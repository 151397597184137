#portal{
    height: 100vh;
    position: relative;
}
.portal__layout-wrapper{
    display: flex;
    height: 100%;
}
.portal__layout-side-navbar-wrapper{
    width: 300px;
    min-width: 300px;
    transition: var(--transition);
}
.portal__layout-side-navbar-wrapper--closed{
    width: 100px;
    min-width: 100px;
}
.portal__layout-side-navbar-wrapper--closed:hover{
    width: 300px;
    min-width: 300px;
}
.portal__layout-content-wrapper{
    flex-grow: 1;
    padding: 90px 20px 50px;
    height: 100%;
    overflow-y: auto;
}
@media (max-width: 768px){
    .portal__layout-side-navbar-wrapper{
        width: 300px;
        position: fixed;
        top: 0;
        height: 100%;
        transition: var(--transition);
        box-shadow: 0 0px 11px 1px rgb(0 0 0 / 20%);
    }
    .portal__layout-side-navbar-wrapper--closed{
        width: 0px;
    }
}