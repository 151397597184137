
.modal__title{
    font-size: 23px;
    font-family: PoppinsBold;
    color:var(--text-color-dark);
    margin: 0;
}
.modal__form-input-wrapper{
    display: flex;
    flex-direction: column;
}
.modal__form-input-label{
    color:var(--text-color-dark);
    font-family: 'PoppinsMedium';
    margin-bottom: 8px;
}
.modal__form-input{
    border: 1px solid #BFBFBF;
    height: 60px;
    border-radius: 4px;
    padding: 20px;
    outline: #BFBFBF;
}
.modal__form-input--error{
    border-color:red
}
.modal__form-input--error::placeholder{
    color: red;
    font-weight: 0;
    font-size:14px
}
.modal__form-input-required{
    color: red;
}

.modal__form-input-error-message{
    color: red;
    font-size: 14px;
    margin-top: 4px;
}