.table-cont{
    background-color: white;
    padding: 20px;
    /* border-radius: 20px; */
    /* box-shadow: 0 0px 20px 0px rgb(0 0 0 / 30%); */
}
.table__title{
    font-size: 23px;
    font-family: PoppinsBold; 
    color:var(--text-color-dark);
    margin: 0;
}
.table__header-wrapper{
    display: flex;
    margin-bottom: 25px;
}
.table__add-button{
    margin-left: auto;
    font-size: 14px;    
    color:var(--text-color-dark);
    display: flex;
    align-items: center;
}
.table__add-icon{
    width: 25px;
    height: 25px;
    margin-right: 3px;
}