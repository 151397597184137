.quick-access{
    margin-bottom: 30px;
}
.quick-access__title{
    font-size: 30px;
    font-family: 'PoppinsBold';
    color: var(--main-color);
    margin-bottom: 20px;
}
.quick-access__items-wrapper{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-gap: 20px;
}
.quick-access__item{

}
.quick-access__link{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    width: 100%;
    height: 100%;
}
.quick-access__item-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: max-content;
    margin: auto;
    transition:var(--transition);
}
.quick-access__item-icon-wrapper{
    background-color: white;
    border-radius: 10px;
    padding: 25px 30px;
    margin-bottom: 10px;
    box-shadow: 0 0px 4px 1px rgb(0 0 0 / 10%);
    transition:var(--transition);
}
.quick-access__item-content:hover{
    box-shadow: none;
    transform: translateY(-5px);

}
.quick-access__item-value{

}
.quick-access__item:nth-of-type(1) .quick-access__item-icon-wrapper{
    background-color: #8ecae6;
}
.quick-access__item:nth-of-type(2) .quick-access__item-icon-wrapper{
    background-color: #219ebc;
}
.quick-access__item:nth-of-type(3) .quick-access__item-icon-wrapper{
    background-color: #023047;
}
.quick-access__item:nth-of-type(4) .quick-access__item-icon-wrapper{
    background-color: #fb8500;
}
.quick-access__item:nth-of-type(5) .quick-access__item-icon-wrapper{
    background-color: #A4668B;
    /* background-color: #941b0c; */
}
.quick-access__item:nth-of-type(6) .quick-access__item-icon-wrapper{
    background-color: #a3b18a;
}
.quick-access__item:nth-of-type(7) .quick-access__item-icon-wrapper{
    background-color: #8ac926;
}
.quick-access__item:nth-of-type(8) .quick-access__item-icon-wrapper{
    background-color: #7678ed;
}
.quick-access__item:nth-of-type(9) .quick-access__item-icon-wrapper{
    /* background-color: #EDB0B8; */
    background-color: #b75d69;
}
.quick-access__item:nth-of-type(10) .quick-access__item-icon-wrapper{
    background-color: #dab49d;
}
.quick-access__item:nth-of-type(11) .quick-access__item-icon-wrapper{
    background-color: #861657;
}
.quick-access__item:nth-of-type(12) .quick-access__item-icon-wrapper{
    /* background-color: #ffc6ff; */
    background-color: #D78D97;
}
.quick-access__item:nth-of-type(1) .quick-access__item-icon path{
    stroke:white
}
.quick-access__item-icon path{
    fill:white
}
.quick-access__item-icon ellipse{
    fill:white
}
.quick-access__item-icon circle{
    fill:white
}